import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FooterComponents from './FooterComponents'
import NavbarComponents from './NavbarComponents'

export default class PageComponents extends Component {
  render() {
    return (
      <>
        <NavbarComponents />
        <div className='bg-img d-flex justify-content-center'>
          <div className='not-found'>
            <h1 className='fw-bold w-100'>404</h1>
            <h2 className='fw-bold w-100'>Halaman Tidak Ditemukan</h2>
            <div className="container">
              <div className="text-dark w-100">
                <hr />
              </div>
              <p className='fw-bold'>Alamat yang anda masukan salah, tidak tersedia, atau sudah dihapus</p>
              <Link to={'/'}>
                <button className='btn btn-primary'>Kembali Ke Beranda</button>
              </Link>
            </div>
          </div>
        </div>
        <FooterComponents />
      </>
    )
  }
}
