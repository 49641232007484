import React, { Component } from "react";
import FooterComponents from "../components/FooterComponents";
import NavbarComponents from "../components/NavbarComponents";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker, Popup, useMap, ZoomControl } from "react-leaflet";
import iconMarker from "../assets/pin.png";
import L from "leaflet";
import ReactHlsPlayer from "@panelist/react-hls-player";
import Marquee from "react-fast-marquee";
import Hls from "hls.js/dist/hls.js";

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.flyTo(center, zoom);
  // map.setView(center, zoom, {
  //     "animate": true,
  //     "pan": {
  //         "duration": 5
  //     }
  // }
  // );
  return null;
}

const SearchBar = ({ onBlur, onKeyDown, placeholder }) => {
  return (
    <>
      <div className="input-group mb-3 ">
        <span
          className="input-group-text text-white "
          style={{ backgroundColor: "#1D4070", border: "solid 2px #1D4070" }}
          id="basic-addon1"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </span>
        <input
          type="text"
          className="form-control me-2"
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          style={{
            border: "solid 2px #1D4070",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
          placeholder="Search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />
        <button
          className="btn btn-md text-white"
          style={{
            backgroundColor: "#1D4070",
            border: "solid 2px #1D4070",
            borderRadius: "5px",
          }}
        >
          Search
        </button>
      </div>
    </>
  );
};

const Stream = ({ stream_cctv }) => {
  return (
    <ReactHlsPlayer
      src={stream_cctv}
      autoPlay={true}
      controls={true}
      width=""
      height="auto"
      className="stream"
    />
  );
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      center: [-6.899503, 107.619231],
      zoom: 13,
      map: null,
      marker: null,
      search: "",
      show: "false",
      filters: [],
    };
    this.markerRefs = [];
    this.handleOnBlurSearch = this.handleOnBlurSearch.bind(this);
  }

  componentDidMount() {
    fetch("https://pelindung.bandung.go.id:8443/api/cek")
      .then((res) => res.json())
      .then(
        (result) => {
          const longeur = result.length;
          this.setState({
            isLoaded: true,
            items: result,
            longeur: longeur,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidUpdate() {
    this.handleClick = (id, lat, lng, stream_cctv) => {
      // if (!this.state.map) return;
      lat = parseFloat(lat) - -0.0015991;
      this.setState({ marker: id, zoom: 18, center: [lat, lng] });
      // this.state.map.setView([lat, lng], 16);
      this.markerRefs[id].openPopup();
      // console.log(lat)
    };
  }

  onClick = (e) => {
    this.setState({
      show: !this.state.show,
    });
    console.log(this.state.show);
  };

  // handleSearchChange = (e) => {
  //     this.setState({ search: e.target.value });
  //     console.log(e.target.value)
  // };

  handleOnBlurSearch(e) {
    e.preventDefault();

    const search = e.target.value.toLowerCase();

    this.setState({
      search: search,
    });

    console.log(search);
  }

  handleKey(e) {
    e.preventDefault();
    const search = e.target.value.toLowerCase();

    this.setState({
      search: search,
    });
  }

  filterFunction = (filters) => {
    return (
      filters.cctv_name.toUpperCase().indexOf(this.state.search.toUpperCase()) >
      -1
    );
  };

  iconMaps = L.icon({
    iconUrl: iconMarker,
    iconSize: [38, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76],
  });

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          <NavbarComponents />
          <div className="row text-center sidebar-main">
            <div className="col-xl-3">
              <div className="sidebar-title">
                <button
                  className="btn btn-light me-2"
                  style={{ color: "#1D4070" }}
                  onClick={() => {
                    this.onClick();
                  }}
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
                <h1 className="fw-bold">{this.state.longeur} CCTV ONLINE</h1>
              </div>

              <div
                className={
                  this.state.show !== false
                    ? "d-block sidebar-maps"
                    : "d-none sidebar-maps"
                }
              >
                <SearchBar
                  placeholder="Search"
                  onBlur={(e) => {
                    this.handleOnBlurSearch(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleKey(e);
                    }
                  }}
                />

                {/* LIST TITIK */}
                <ul className="list-group text-start text-uppercase">
                  {items
                    .filter(this.filterFunction)
                    .map(({ lat, lng, id, cctv_name, stream_cctv }) => (
                      <li
                        key={id}
                        className="list-group-item"
                        onClick={() => {
                          this.handleClick(id, lat, lng, stream_cctv);
                        }}
                      >
                        <img
                          src={iconMarker}
                          className="img-fluid me-2"
                          width={20}
                          alt=""
                        />{" "}
                        {cctv_name}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="maps">
            <MapContainer
              id="maps"
              center={this.state.center}
              zoom={this.state.zoom}
              zoomControl={false}
              whenCreated={(map) => this.setState({ map })}
            >
              <TileLayer
                attribution="&amp;copy DEVELOPED BY IT SUPPORT BCC"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                style={{ fontSize: "5px" }}
              />
              <ZoomControl position="bottomright" />
              {items.map(({ lat, lng, id, cctv_name, stream_cctv }) => (
                <Marker
                  key={id}
                  position={[lat, lng]}
                  icon={this.iconMaps}
                  ref={(ref) => {
                    this.markerRefs[`${id}`] = ref;
                  }}
                >
                  <ChangeView
                    center={this.state.center}
                    zoom={this.state.zoom}
                  />
                  <Popup className="request-popup">
                    <h6 className="">{cctv_name}</h6>
                    <Stream stream_cctv={stream_cctv} />
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
          <Marquee
            gradient={false}
            className="text-uppercase font-weight-bold text-white"
          >
            <span
              style={{ fontSize: "12px", backgroundColor: "rgba(0, 0, 0, 0.7" }}
              className="p-2"
            >
              <img
                alt=""
                className="ms-3 me-3"
                src="./images/BCC.png"
                width="25px"
              />{" "}
              DEVELOPED BY : Yusuf Cahyadi, Ali Hanifa, Moch Vianto Rakanda W,
              Fatwa, M. Nuridho (TEAM IT BANDUNG COMMAND
              CENTER) Andris S (UI/UX Design) © 2021
              <img
                alt=""
                className="ms-3 me-3"
                src="./images/BCC.png"
                width="25px"
              />{" "}
              DEVELOPED BY : Yusuf Cahyadi, Ali Hanifa, Moch Vianto Rakanda W,
              Fatwa, M. Nuridho (TEAM IT BANDUNG COMMAND
              CENTER) Andris S (UI/UX Design) © 2021
              <img
                alt=""
                className="ms-3 me-3"
                src="./images/BCC.png"
                width="25px"
              />{" "}
              DEVELOPED BY : Yusuf Cahyadi, Ali Hanifa, Moch Vianto Rakanda W,
              Fatwa, M. Nuridho (TEAM IT BANDUNG COMMAND
              CENTER) Andris S (UI/UX Design) © 2021
              <img
                alt=""
                className="ms-3 me-3"
                src="./images/BCC.png"
                width="25px"
              />{" "}
              DEVELOPED BY : Yusuf Cahyadi, Ali Hanifa, Moch Vianto Rakanda W,
              Fatwa, M. Nuridho (TEAM IT BANDUNG COMMAND
              CENTER) Andris S (UI/UX Design) © 2021
              <img
                alt=""
                className="ms-3 me-3"
                src="./images/BCC.png"
                width="25px"
              />{" "}
              DEVELOPED BY : Yusuf Cahyadi, Ali Hanifa, Moch Vianto Rakanda W,
              Fatwa, M. Nuridho (TEAM IT BANDUNG COMMAND
              CENTER) Andris S (UI/UX Design) © 2021
            </span>
          </Marquee>
          <FooterComponents />
        </>
      );
    }
  }
}
