import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const NavbarComponents = () => {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return (
    <>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href="/">
            <div className="container-fluid">
              <img src="./images/logo.png" alt="" className="img-fluid" />
            </div>
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link className="tanggal text-white fw-bolder border border-white rounded">
              {date}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponents;
