import './App.css';
import Maps from './maps/index'
import Page from './components/PageComponents';
import { BrowserRouter, Route, Routes, } from 'react-router-dom'
import ReactGA from 'react-ga';
import { useEffect } from 'react';
ReactGA.initialize('UA-227574428-1');


function App() {

  // REACT GA
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>

          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Maps />} />

          <Route path="*" element={<Page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

