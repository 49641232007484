import React from "react";

const FooterComponents = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row align-items-start">
            <div className="col-lg-3 col-md-3 mt-3 mb-3 info-kontak">
              {/* <img src="/images/footer-logo.png" width={300} height={65} alt="ss" className='img-fluid footer-logo' /> */}
              <h6 className="text-white fw-bold">KETERANGAN</h6>
              {/* <span className='text-white fw-bold'>(022) 73517371</span> */}
              {/* <p className='text-white fw-bold mt-2'>JL. Wastukencana No. 2, Babakan Ciamis, Kota Bandung Jawa Barat</p> */}
              <span className="text-white fw-bold">TB : TITIK BANJIR</span>
              <br />
              <span className="text-white fw-bold">TM : TITIK TAMAN</span>
              <br />
              <span className="text-white fw-bold">SP : SIMPANG</span>
            </div>
            <div className="col-lg-3 col-md-3 mt-3 support-by">
              <p className="text-light fw-bold text-uppercase mb-3">
                Support by
              </p>
              <div className="row">
                <div className="col-xl-4 d-flex align-items-center">
                  <img
                    src="/images/BCC.png"
                    alt=""
                    className="img-fluid me-3 logo-bcc"
                  />
                  <img
                    src="/images/Diskominfo.png"
                    alt=""
                    className="img-fluid me-3 logo-diskominfo"
                  />
                  {/* <img src="/images/DPU.png" alt="" className='img-fluid me-3 logo-dpu' /> */}
                  {/* <img src="/images/dishub.png" alt="" className='img-fluid me-3 logo-dpu' /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3 col-md-3 sosial-media">
              <p className="text-light fw-bold text-uppercase mb-3">
                Sosial Media
              </p>
              <div className="row">
                <div className="col-xl-4 d-flex align-items-center">
                  <a
                    href="https://www.facebook.com/groups/987031504750776/?locale=id_ID"
                    target={"_blank"}
                  >
                    <img
                      src="/images/FB.png"
                      alt=""
                      className="img-fluid me-4 logo-fb"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@diskominfokotabandung"
                    target={"_blank"}
                  >
                    <img
                      src="/images/YT.png"
                      alt=""
                      className="img-fluid me-4 logo-ig"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/bandungsiaga112/"
                    target={"_blank"}
                  >
                    <img
                      src="/images/IG.png"
                      alt=""
                      className="img-fluid me-4 logo-yt"
                    />
                  </a>
                  <a href="https://twitter.com/humasbandung_" target={"_blank"}>
                    <img
                      src="/images/TWITTER.png"
                      alt=""
                      className="img-fluid me-4 logo-yt"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 mt-3 footer-logo d-flex align-items-center">
              <img
                src="/images/footer-logo.png"
                alt="ss"
                className="img-fluid"
              />
              {/* <h6 className='text-white fw-bold'>KONTAK</h6>
                            <span className='text-white fw-bold'>(022) 73517371</span>
                            <p className='text-white fw-bold mt-2'>JL. Wastukencana No. 2, Babakan Ciamis, Kota Bandung Jawa Barat</p> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterComponents;
